/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/moment@2.22.0/moment.min.js
 * - /npm/moment@2.22.2/locale/id.min.js
 * - /npm/jquery@3.3.1/dist/jquery.slim.min.js
 * - /npm/popper.js@1.14.3/dist/umd/popper.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/bootstrap-material-design@4.1.1/dist/js/bootstrap-material-design.min.js
 * - /npm/sweetalert@2.1.0/dist/sweetalert.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
